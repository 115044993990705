<template>
  <div :id="$style.editList">
    <template v-if="isConfirmDeletePromptVisible && currentActionConfig">
      <div :class="$style.confirmContainer">
        <h2>{{ currentActionConfig.headerText }}</h2>
        <div
          v-if="deleteListStatusError || deleteTrackStatusError"
          :class="$style.deleteError"
        >
          There was a problem during deletion. Please try again.
        </div>
        <div :class="$style.confirmActions">
          <BaseButton
            :disabled="deleteListStatusPending || deleteTrackStatusPending"
            variant="primary"
            @click.prevent="isConfirmDeletePromptVisible = false"
            >CANCEL</BaseButton
          >
          <BaseButton
            :class="$style.deleteBtn"
            :disabled="deleteListStatusPending || deleteTrackStatusPending"
            @click="
              () => {
                this[currentActionConfig.handler]();
              }
            "
            variant="primary-solid"
          >
            {{
              deleteListStatusPending || deleteTrackStatusPending
                ? "DELETING..."
                : currentActionConfig.ctaText
            }}</BaseButton
          >
        </div>
      </div>
    </template>
    <template v-else>
      <div>
        <input
          :class="[styles.heading, styles.inputAsHeading]"
          v-model="listName"
          placeholder="Search lists..."
        />
        <div>
          <ul v-if="fetchListTracksStatusSuccess" :class="$style.tracksList">
            <li
              v-for="[iswc, track] of Array.from(tracks)"
              :key="iswc"
              :class="$style.tracksItem"
            >
              <button
                :class="$style.deleteTrackBtn"
                @click="showDeleteTrackConfirmPrompt(track)"
              >
                <BaseIcon icon="times" />
              </button>
              <div :class="$style.trackDetails">
                <span :class="$style.trackTitle">{{ track.title }}</span>
                <span :class="$style.trackIswc">{{
                  track.iswcs.join(", ")
                }}</span>
              </div>
            </li>
          </ul>
          <div v-else-if="fetchListTracksStatusPending">Loading tracks...</div>
        </div>
      </div>

      <div>
        <BaseButton
          variant="primary-solid"
          :class="$style.deleteBtn"
          @click.prevent="showDeleteListConfirmPrompt"
          >DELETE THIS LIST</BaseButton
        >
      </div>
    </template>
  </div>
</template>

<script>
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { withAsync } from "@/helpers";
import { API_STATUS } from "@/constants/apiStatus";
import {
  updateUserListName,
  deleteUserList,
  deleteUserListTrack,
  searchUserListTracks,
} from "@/api/userTracksListApi";
import { LoadMore } from "@/components/common";

const { IDLE, PENDING, SUCCESS, ERROR, LOADING_MORE } = API_STATUS;

const ACTIONS = {
  DELETE_LIST: "DELETE_LIST",
  DELETE_TRACK: "DELETE_TRACK",
};

const ACTION_CONFIG = {
  [ACTIONS.DELETE_LIST]: {
    headerText: "Are you sure you want to delete this list?",
    ctaText: "DELETE LIST",
    handler: "initDeleteList",
  },
  [ACTIONS.DELETE_TRACK]: {
    headerText: "Are you sure you want to delete this track?",
    ctaText: "DELETE TRACK",
    handler: "initDeleteTrack",
  },
};

export default {
  name: "EditList",
  props: {
    selectedList: {
      type: Object,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      listName: this.selectedList?.name,
      deleteListStatus: IDLE,
      deleteTrackStatus: IDLE,
      fetchListTracksStatus: IDLE,
      isConfirmDeletePromptVisible: false,
      page: 0,
      tracks: new Map(),
      currentAction: null,
    };
  },
  watch: {
    listName: "scheduleListNameUpdate",
  },
  created() {
    this.fetchListTracks(this.page);
  },
  computed: {
    ...apiStatusComputedFactory([
      "deleteListStatus",
      "deleteTrackStatus",
      "fetchListTracksStatus",
    ]),
    currentActionConfig() {
      if (!this.currentAction) return;

      return ACTION_CONFIG[this.currentAction];
    },
  },
  methods: {
    showDeleteListConfirmPrompt() {
      this.currentAction = ACTIONS.DELETE_LIST;
      this.isConfirmDeletePromptVisible = true;
    },
    showDeleteTrackConfirmPrompt(track) {
      this.selectedTrackToDelete = track;
      this.currentAction = ACTIONS.DELETE_TRACK;
      this.isConfirmDeletePromptVisible = true;
    },
    async initDeleteTrack() {
      this.deleteTrackStatus = PENDING;
      const { response, error } = await withAsync(() =>
        deleteUserListTrack({
          listId: this.selectedList.id,
          objectID: this.selectedTrackToDelete.objectID,
        })
      );

      if (error) {
        this.deleteTrackStatus = ERROR;
        return;
      }
      this.isConfirmDeletePromptVisible = false;
      this.tracks.delete(this.selectedTrackToDelete.objectID);
      this.selectedTrackToDelete = null;
      this.currentAction = null;
      this.deleteTrackStatus = SUCCESS;
      this.$forceUpdate();
    },
    async fetchListTracks(page = this.page) {
      this.fetchListTracksStatus = PENDING;
      const { response, error } = await withAsync(() =>
        searchUserListTracks({
          listId: this.selectedList.id,
          query: "",
          page,
        })
      );

      if (error) {
        this.fetchListTracksStatus = ERROR;
        return;
      }
      for (const track of response.hits) {
        this.tracks.set(track.objectID, track);
      }
      // this.$set(this, "tracks", this.tracks);
      this.$forceUpdate();
      this.fetchListTracksStatus = SUCCESS;
    },
    async initDeleteList() {
      this.deleteListStatus = PENDING;
      const { response, error } = await withAsync(() =>
        deleteUserList({
          listId: this.selectedList.id,
        })
      );

      if (error) {
        this.deleteListStatus = ERROR;
        return;
      }

      this.deleteListStatus = SUCCESS;
      this.$emit("changeCurrentComponent", "SEARCH_LISTS");
    },
    async scheduleListNameUpdate(listName) {
      clearTimeout(this.listNameUpdateTimeout);
      this.listNameUpdateTimeout = setTimeout(async () => {
        const { response, error } = await withAsync(() =>
          updateUserListName({
            listId: this.selectedList.id,
            name: listName,
          })
        );
      }, [2000]);
    },
  },
};
</script>

<style lang="scss" module>
#editList {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .deleteBtn {
    width: 100%;
    background-color: $color-text-error;
    color: white;
  }
}

.confirmContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tracksList {
  padding: 0;
  list-style: none;
  max-height: 390px;
  overflow-y: auto;
}

.tracksItem {
  display: flex;
  gap: 1rem;
  & + & {
    margin-top: 1.5rem;
  }
}

.trackDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.trackTitle {
  font-weight: 500;
}

.trackIswc {
  color: $color-grey-light;
}

.confirmActions {
  display: flex;
  width: 100%;
  gap: 2rem;
  margin-top: 3rem;
  & > * {
    flex-basis: 50%;
  }
}

.deleteTrackBtn {
  background: transparent;
  border: none;
  font-size: 1.25rem;
  color: $color-text-error;
  cursor: pointer;
}

.deleteError {
  margin: 2rem;
  color: $color-text-error;
}
</style>