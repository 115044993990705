var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.$style.editList}},[(_vm.isConfirmDeletePromptVisible && _vm.currentActionConfig)?[_c('div',{class:_vm.$style.confirmContainer},[_c('h2',[_vm._v(_vm._s(_vm.currentActionConfig.headerText))]),(_vm.deleteListStatusError || _vm.deleteTrackStatusError)?_c('div',{class:_vm.$style.deleteError},[_vm._v(" There was a problem during deletion. Please try again. ")]):_vm._e(),_c('div',{class:_vm.$style.confirmActions},[_c('BaseButton',{attrs:{"disabled":_vm.deleteListStatusPending || _vm.deleteTrackStatusPending,"variant":"primary"},on:{"click":function($event){$event.preventDefault();_vm.isConfirmDeletePromptVisible = false}}},[_vm._v("CANCEL")]),_c('BaseButton',{class:_vm.$style.deleteBtn,attrs:{"disabled":_vm.deleteListStatusPending || _vm.deleteTrackStatusPending,"variant":"primary-solid"},on:{"click":function () {
              this$1[_vm.currentActionConfig.handler]();
            }}},[_vm._v(" "+_vm._s(_vm.deleteListStatusPending || _vm.deleteTrackStatusPending ? "DELETING..." : _vm.currentActionConfig.ctaText))])],1)])]:[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.listName),expression:"listName"}],class:[_vm.styles.heading, _vm.styles.inputAsHeading],attrs:{"placeholder":"Search lists..."},domProps:{"value":(_vm.listName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.listName=$event.target.value}}}),_c('div',[(_vm.fetchListTracksStatusSuccess)?_c('ul',{class:_vm.$style.tracksList},_vm._l((Array.from(_vm.tracks)),function(ref){
            var iswc = ref[0];
            var track = ref[1];
return _c('li',{key:iswc,class:_vm.$style.tracksItem},[_c('button',{class:_vm.$style.deleteTrackBtn,on:{"click":function($event){return _vm.showDeleteTrackConfirmPrompt(track)}}},[_c('BaseIcon',{attrs:{"icon":"times"}})],1),_c('div',{class:_vm.$style.trackDetails},[_c('span',{class:_vm.$style.trackTitle},[_vm._v(_vm._s(track.title))]),_c('span',{class:_vm.$style.trackIswc},[_vm._v(_vm._s(track.iswcs.join(", ")))])])])}),0):(_vm.fetchListTracksStatusPending)?_c('div',[_vm._v("Loading tracks...")]):_vm._e()])]),_c('div',[_c('BaseButton',{class:_vm.$style.deleteBtn,attrs:{"variant":"primary-solid"},on:{"click":function($event){$event.preventDefault();return _vm.showDeleteListConfirmPrompt.apply(null, arguments)}}},[_vm._v("DELETE THIS LIST")])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }